@mixin cf() {
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin min($device-width) {
  @media screen and (min-width: $device-width) {
    @content;
  }
}

@mixin max($device-width) {
  @media screen and (max-width: $device-width - 1) {
    @content;
  }
}
@mixin minmax($device-width1, $device-width2) {
  @media screen and (min-width: $device-width1) and (max-width: $device-width2 - 1) {
    @content;
  }
}

@mixin centerer($horizontal: true, $vertical: true) {
  position: absolute;
  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  } @else if ($horizontal) {
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  } @else if ($vertical) {
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

%text-heading {
  font-family: $font-heading;
  letter-spacing: 1.1;
}