/***** Video bottom tabs *****/

.course-data {
  padding: 0 16px;
  @include max($lg) {
    padding: 0;
  }
}
.course-data-tabs {
  display: flex;
  align-items: stretch;
  padding: 0 16px;
  box-shadow: inset 0 -1px 0 0 #d1d7dc;
  width: 100%;
  @include max($xxxs) {
    padding: 0 8px;
  }
}
.course-data-tab {
  padding: 8px 4px;
  font-weight: bold;
  font-size: 16px;
  line-height: 32px;
  height: 48px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  transition: all 0.2s;
  @include max($sm) {
    font-size: 14px;
  }
  @include max($xxxs) {
    font-size: 12px;
  }
  &:hover {
    opacity: 0.8;
  }
  &:not(:last-child) {
    margin-right: 16px;
    @include max($sm) {
      margin-right: 8px;
    }
    @include max($xxs) {
      margin-right: 4px;
    }
  }
  &.is-active {
    border-bottom-color: #1c1d1f;
    &:hover {
      opacity: 1;
    }
  }
}

.course-data-contents {
  padding: 16px 0 96px;
  @include max($lg) {
    padding: 16px 0 32px;
  }
  .course-data-content {
  }
}

[dir="rtl"] {
  .course-data-tab {
    &:not(:last-child) {
      margin: 0 0 0 16px;
      @include max($sm) {
        margin: 0 0 0 8px;
      }
      @include max($xxs) {
        margin: 0 0 0 4px;
      }
    }
  }
}

/**** Sidebar Mobile ****/
.course-data-content-sidebar {
  padding: 32px 24px 0;
  max-width: 848px;
  margin: 0 auto;
  @include max($sm) {
    padding: 8px 24px 0;
  }
}

/**** Summary ****/
.course-data-content-summary {
  .show-more-container {
    &.is-active {
      overflow: hidden;
      max-height: 650px;
      position: relative;
      mask-image: linear-gradient(#fff, #fff, rgba(255, 255, 255, 0));
      -webkit-mask-image: linear-gradient(#fff, #fff, rgba(255, 255, 255, 0));
    }
  }
  .show-more {
    padding-left: 24px;
    .btn {
      color: #5624d0;
      cursor: pointer;
      &:hover {
        color: #401b9c;
      }
    }
  }
  .summary-top {
    padding: 32px 24px;
    @include max($sm) {
      padding: 8px 24px 24px;
    }
    h2.summary-label {
      margin: 0 0 16px;
      font-size: 24px;
    }
    .summary-shortdesc {
      p {
        margin: 0;
      }
    }
  }
  h3.summary-label {
    width: 25%;
    flex-shrink: 0;
    margin: 0;
    font-weight: normal;
    font-size: 16px;
    @include max($lg) {
      width: 100%;
      margin-bottom: 16px;
    }
  }
  .summary-stats {
    display: flex;
    border-top: 1px solid #d1d7dc;
    padding: 24px;
    @include max($lg) {
      display: block;
    }
    .summary-stats-groups {
      display: flex;
      flex: 1;
      @include max($sm) {
        display: block;
      }
    }
    .summary-stats-group {
      width: 44.4444%;
      flex-shrink: 0;
      @include max($sm) {
        width: 100%;
      }
    }
    .summary-stats-item {
    }
  }
  .summary-features {
    display: flex;
    border-top: 1px solid #d1d7dc;
    padding: 24px;
    @include max($lg) {
      display: block;
    }
    .summary-features-content {
    }
  }
  .summary-description {
    padding: 24px;
    display: flex;
    border-top: 1px solid #d1d7dc;
    @include max($lg) {
      display: block;
    }
    .summary-description-content {
    }
  }
  .summary-instructor {
    padding: 24px;
    display: flex;
    border-top: 1px solid #d1d7dc;
    @include max($lg) {
      display: block;
    }
    .summary-instructor-content {
      width: 75%;
      flex-shrink: 0;
      @include max($lg) {
        width: 100%;
      }
    }
    .summary-instructor-top {
      margin-bottom: 24px;
      display: flex;
    }
    .summary-instructor-userpic {
      width: 64px;
      height: 64px;
      flex-shrink: 0;
      margin-right: 24px;
      line-height: 0;
      img {
        border-radius: 50%;
      }
    }
    .summary-instructor-info {
    }
    .summary-instructor-name {
      font-weight: bold;
      font-size: 19px;
    }
    .summary-instructor-profile {
      max-width: 600px;
    }
    .summary-instructor-links {
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      a.icon-w {
        width: 32px;
        height: 32px;
        background: #666;
        transition: all 0.2s;
        color: #fff;
        margin-right: 16px;
        &:hover {
          background: #888;
        }
        .icon {
        }
      }
    }
    .summary-instructor-description {
    }
  }
}

[dir="rtl"] {
  .course-data-content-summary {
    .show-more {
      padding: 0 24px 0 0;
    }
    .summary-instructor {
      margin: 0 0 0 24px;
    }
    .summary-instructor-links {
      a.icon-w {
        margin: 0 0 0 16px;
      }
    }
  }
}

/***** Notes *****/

.course-data-content-notes {
  padding: 32px 24px;
  max-width: 840px;
  margin: 0 auto;
  .notes-add-form {
    margin-bottom: 16px;
    display: flex;
    align-items: flex-start;
    .note-timer {
      width: 48px;
      flex: 0 0 48px;
      margin: 0 16px 0 0;
      background: #222;
      border-radius: 12px;
      padding: 0 4px;
      text-align: center;
      font-weight: bold;
      color: #fff;
      font-size: 14px;
      line-height: 24px;
    }
    .notes-add-form-r {
      flex: 1;
      .form-group {
        margin-bottom: 8px;
      }
      .form-control {
        resize: none;
        height: 70px;
      }
      .btns {
        justify-content: flex-end;
        margin-bottom: 16px;
        .btn {
          &:not(:last-child) {
            margin-right: 16px;
          }
        }
      }
    }
  }
  .notes-edit-form {
    .form-group {
      margin-bottom: 32px;
    }
    .form-control {
      resize: none;
      height: 70px;
    }
    .btns {
      justify-content: flex-end;
      .btn {
        &:not(:last-child) {
          margin-right: 16px;
        }
      }
    }
  }

  .notes-create {
    .btn-notes-create {
      width: 100%;
      justify-content: space-between;
      margin-bottom: 8px;
      .btn-txt {
        font-weight: normal;
        font-size: 16px;
        color: #6a6f73;
      }
      &:hover {
        background: #f5f5f5;
      }
    }
  }

  .notes-search-form {
    position: relative;
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      width: 48px;
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23000000"><path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0016 9.5 6.5 6.5 0 109.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path></svg>')
        50% 50% no-repeat;
      background-size: 30px 30px;
      opacity: 0.1;
    }
  }

  .notes-sort-filter {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 32px;
    .popper-dropdown {
      margin-bottom: 4px !important;
      &:not(:last-child) {
        margin-right: 8px !important;
      }
    }
  }

  .notes-list {
    .note {
      display: flex;
      align-items: flex-start;
      &:not(:last-child) {
        margin-bottom: 32px;
      }
      .note-timer {
        width: 48px;
        flex: 0 0 48px;
        margin: 6px 16px 0 0;
        background: #222;
        border-radius: 12px;
        padding: 0 4px;
        text-align: center;
        font-weight: bold;
        color: #fff;
        font-size: 14px;
        line-height: 24px;
        cursor: pointer;
      }
      .note-r {
        flex: 1;
      }
      .note-h {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
      }
      .note-title {
        cursor: pointer;
      }
      .note-chapter {
        font-weight: bold;
        margin-right: 8px;
      }
      .note-lesson {
        font-size: 14px;
      }
      .note-actions {
        margin: 0 0 0 auto;
        display: flex;
        align-items: center;
        .icon-w {
          width: 36px;
          height: 36px;
          cursor: pointer;
          padding: 8px;
          transition: all 0.2s;
          &:hover {
            opacity: 0.8;
          }
          &:not(:last-child) {
          }
        }
      }
      .note-content {
        padding: 24px;
        background: #f7f9fa;
      }
      mark {
        background: #fff9a0;
      }
    }
  }
}

[dir="rtl"] {
  .course-data-content-notes {
    .notes-add-form {
      .note-timer {
        margin: 0 0 0 16px;
      }
      .notes-add-form-r {
        .btns {
          .btn {
            &:not(:last-child) {
              margin: 0 0 0 16px;
            }
          }
        }
      }
    }
    .notes-edit-form {
      .btns {
        .btn {
          &:not(:last-child) {
            margin: 0 0 0 16px;
          }
        }
      }
    }
    .notes-sort-filter {
      .popper-dropdown {
        &:not(:last-child) {
          margin: 0 0 0 8px !important;
        }
      }
    }

    .notes-list {
      .note {
        .note-timer {
          margin: 6px 0 0 16px;
        }
        .note-chapter {
          margin: 0 0 0 8px;
        }
        .note-actions {
          margin: 0 auto 0 0;
        }
      }
    }
  }
}

/***** Announcements *****/
.course-data-content-ann {
  max-width: 848px;
  padding: 32px 24px 0;
  margin: 0 auto;
  @include max($lg) {
    padding: 8px 24px 0;
  }
}
.ann-list {
}
.ann-item {
  &:not(:last-child) {
    margin-bottom: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid #d1d7dc;
  }
  .ann-top {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    .ann-userpic {
      width: 48px;
      flex: 0 0 48px;
      margin-right: 16px;
      img {
        border-radius: 50%;
      }
    }
    .ann-info {
      flex: 1;
    }
    .ann-name {
    }
    .ann-info-b {
      .ann-posted {
      }
      .ann-postdate {
      }
      .ann-abuse {
        vertical-align: middle;
        line-height: 19px;
        display: inline-block;
        cursor: pointer;
        transition: all 0.2s;
        &:hover {
          opacity: 0.8;
        }
      }
      .ann-bullet {
        margin: 0 6px;
      }
    }
  }

  .ann-content-w {
    margin-bottom: 16px;
    .ann-title {
      font-weight: bold;
      font-size: 19px;
      margin-bottom: 16px;
    }
    .ann-content {
    }
  }
}

.ann-comments-container {
}
.ann-comment-form {
  display: flex;
  margin-bottom: 16px;
  .ann-comment-form-userpic {
    width: 48px;
    flex: 0 0 48px;
    margin-right: 16px;
    img {
      border-radius: 50%;
    }
  }
  .ann-comment-form-textarea {
    flex: 1;
    .form-control {
      resize: none;
    }
    .ann-comment-help {
      font-size: 12px;
      color: #777;
      margin-top: 8px;
    }
  }
}

.ann-comments-w {
  .ann-comments-toggler {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
      opacity: 0.8;
    }
  }
}
.ann-comments {
}
.ann-comment {
  display: flex;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  .ann-comment-userpic {
    width: 48px;
    flex: 0 0 48px;
    margin-right: 16px;
    img {
      border-radius: 50%;
    }
  }
  .ann-comment-r {
    flex: 1;
  }
  .ann-comment-info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .ann-comment-name {
    }
    .ann-comment-posted {
    }
    .ann-comment-postdate {
    }
    .ann-comment-abuse {
      vertical-align: middle;
      line-height: 19px;
      display: inline-block;
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
        opacity: 0.8;
      }
    }
    .ann-comment-bullet {
      margin: 0 6px;
    }
  }
}

.ann-comments-more {
  margin-top: 32px;
}

[dir="rtl"] {
  .ann-item {
    .ann-top {
      .ann-userpic {
        margin: 0 0 0 16px;
      }
    }
  }
  .ann-comment-form {
    .ann-comment-form-userpic {
      margin: 0 0 0 16px;
    }
  }
  .ann-comment {
    .ann-comment-userpic {
      margin: 0 0 0 16px;
    }
  }
}

/*** Questions ***/

.course-data-content-qa {
  padding: 32px 24px;
  max-width: 840px;
  margin: 0 auto;
  @include max($sm) {
    padding: 8px 24px 0;
  }
}

.qa-create {
  margin-top: 16px;
  .btn-qa-create {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 8px;
    .btn-txt {
      font-weight: normal;
      font-size: 16px;
      color: #6a6f73;
    }
    &:hover {
      background: #f5f5f5;
    }
  }
  .link-qa-create {
    text-decoration: underline;
    font-size: 14px;
    font-weight: bold;
    transition: all 0.2s;
    // color: #5624d0;
    cursor: pointer;
    &:hover {
      color: #666;
      // color: #401b9c;
    }
  }
  .form-group {
    margin-bottom: 8px;
  }
  .form-control {
    resize: none;
    height: 70px;
  }
  .btns {
    justify-content: flex-end;
    margin-bottom: 16px;
    .btn {
      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }
}

.qa-search-form {
  position: relative;
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    width: 48px;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23000000"><path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0016 9.5 6.5 6.5 0 109.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path></svg>')
      50% 50% no-repeat;
    background-size: 30px 30px;
    opacity: 0.1;
  }
}
.qa-list {
  .qa {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    .question {
      font-weight: bold;
      margin-bottom: 5px;
    }
    .answer {
      &.answer-empty {
        color: #aaa;
      }
    }
    mark {
      background: #fff9a0;
    }
  }
}
