/***** Header *****/

.header {
  height: 55px;
  flex: 0 0 55px;
  display: flex;
  padding: 0 16px;
  color: #fff;
  .header-h {
    display: flex;
    align-items: center;
    margin-right: 40px;
    flex: 0 0 50%;
    @include max($lg) {
      flex: 1;
      margin-right: 24px;
    }
  }
  .logo {
    display: flex;
    align-items: center;
    margin-right: 24px;
    padding-right: 24px;
    border-right: 1px solid #444;
    line-height: 1;
    @include max($sm) {
      margin-right: 8px;
      padding-right: 8px;
    }
    img {
      height: 20px;
      flex: 0 0 40px;
      margin-right: 10px;
      @include max($sm) {
        margin: 0;
      }
    }
    span {
      font-size: 24px;
      font-weight: bold;
      @include max($sm) {
        display: none;
      }
    }
  }
  h1 {
    margin: 0;
    font-size: 16px;
  }

  .header-nav {
    display: flex;
    align-items: center;
    margin-left: auto;

    // .header-rating {
    //   display: flex;
    //   align-items: center;
    //   margin-right: 24px;
    //   padding: 10px;
    //   cursor: pointer;
    //   transition: all 0.2s;
    //   &:hover {
    //     opacity: 0.8;
    //   }
    //   .icon-w {
    //     width: 16px;
    //     height: 16px;
    //     margin-right: 8px;
    //     .icon {
    //       fill: #999;
    //     }
    //   }
    //   .label {
    //   }
    // }
    .header-progress {
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: all 0.2s;
      @include max($lg) {
        flex: 0 0 35px;
      }
      &:hover {
        opacity: 0.8;
      }
      .progress-i {
        display: flex;
        align-items: center;
      }
      .progress-bar {
        height: 35px;
        width: 35px;
        margin-right: 8px;
        transform: rotate(-90deg);
        svg {
          circle {
            stroke-dashoffset: 0;
            transition: stroke-dashoffset 1s linear;
            stroke: #344;
            stroke-width: 2px;
          }
          .bar {
            stroke: #cce;
          }
        }
      }
      .label {
        @include max($lg) {
          display: none;
        }
      }
      .icon {
      }
      .progress-dropdown {
        flex: 0 0 20px;
        height: 20px;
        width: 20px;
        margin-left: 8px;
        @include max($lg) {
          display: none;
        }
      }
    }
    .popper-dropdown-progress {
      position: relative;
      .dropdown-w {
      }
      .popper {
        border: 1px solid #d1d7dc;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08),
          0 4px 12px rgba(0, 0, 0, 0.08);
      }
      .dropdown-txt {
        color: #222;
        font-weight: bold;
        font-size: 14px;
        padding: 24px;
        background: #fff;

        min-width: 300px;
        position: relative;
        z-index: 1;
      }
      [data-popper-arrow] {
        &:before {
          background: #fff !important;
          border: 1px solid #d1d7dc !important;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08),
            0 4px 12px rgba(0, 0, 0, 0.08) !important;
          width: 24px !important;
          height: 24px !important;
          transform: scaleX(0.8) rotate(45deg) !important;
        }
      }
    }

    .lang-switcher {
      margin-left: 20px;
      .lang {
        cursor: pointer;
        transition: all 0.2s;
        min-width: 30px;
        text-align: center;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

[dir="rtl"] {
  .header {
    .header-h {
      margin: 0 0 0 40px;
      @include max($lg) {
        margin: 0 0 0 24px;
      }
    }
    .logo {
      margin: 0 0 0 24px;
      padding: 0 0 0 24px;
      border-right: none;
      border-left: 1px solid #444;
      @include max($sm) {
        margin: 0 0 0 8px;
        padding: 0 0 0 8px;
      }
      img {
        margin: 0 0 0 10px;
        @include max($sm) {
          margin: 0;
        }
      }
    }
    .header-nav {
      display: flex;
      align-items: center;
      margin: 0 auto 0 0;
      .header-progress {
        .progress-bar {
          height: 35px;
          width: 35px;
          margin: 0 0 0 8px;
        }
        .progress-dropdown {
          margin: 0 8px 0 0;
        }
      }
      .lang-switcher {
        margin: 0 20px 0 0;
      }
    }
  }
}
