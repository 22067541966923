/* Color */
$grey: #959595;
$lgrey: #f5f5f5;
$black: #22262e;

$font-heading: "SF Pro Display", sf pro text, -apple-system, BlinkMacSystemFont,
  Roboto, segoe ui, Helvetica, Arial, sans-serif, apple color emoji,
  segoe ui emoji, segoe ui symbol;
$font-basic: "SF Pro Display", sf pro text, -apple-system, BlinkMacSystemFont,
  Roboto, segoe ui, Helvetica, Arial, sans-serif, apple color emoji,
  segoe ui emoji, segoe ui symbol;

/* Size */
$xxxs: 360px;
$xxs: 400px;
$xs: 480px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1280px;
$xxxl: 1400px;
$huge: 1600px;
$xhuge: 1800px;
$xxhuge: 2000px;
