/***** Simple elements, atoms *****/

.icon-w {
  display: flex;
  align-items: center;
  justify-content: center;
  .icon {
    fill: currentColor;
    max-height: 100%;
    max-width: 100%;
  }
}
