/***** Video *****/

.course-video {
  position: relative;
  .course-video-i1 {
    position: relative;
    height: 100%;
    overflow: hidden;
    min-height: 400px;
    max-height: calc(100vh - 200px);
    @include max($xxl) {
      min-height: 300px;
    }
    @include max($sm) {
      min-height: 0;
    }
  }
  .course-video-i2 {
    padding-top: 56.25%;
    overflow: hidden;
  }
  .course-video-i3 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  // .course-video-i4 {
  //   min-height: 400px;
  //   position: relative;
  //   height: 100%;
  //   overflow: hidden;
  //   max-height: calc(100vh - 200px);
  // }
}

.video-js {
  padding-top: 0% !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  .vjs-tech {
  }
  .vjs-poster {
    background-size: cover !important;
  }
  .vjs-control {
    height: 34px;
    width: 34px;
    padding: 0 6px;
    margin: 0 2px;
    @include max($sm) {
      margin: 0;
    }
  }
  .vjs-control-bar {
    width: 100%;
    flex-wrap: wrap;
    height: auto;
    display: flex;
    // transition: all 1s;
    padding: 0 12px 6px;
    background: linear-gradient(
      to top,
      rgba(#111, 0.9) 0%,
      rgba(#111, 0.5) 35%,
      rgba(#111, 0.2) 65%,
      rgba(#111, 0) 100%
    );
    // opacity: 0;
    // visibility: hidden;
  }
  .vjs-progress-control {
    width: 100%;
    flex: 0 0 100%;
    padding: 9px 0;
    margin: 0;
    order: 1;
    @include max($sm) {
      padding: 8px 0;
    }
    .vjs-progress-holder {
      height: 6px;
      margin: 0;
      background-color: #556;
    }
    .vjs-load-progress {
      height: 6px;
      transition: all 0.2s;
      div {
        background: #778;
      }
    }
    .vjs-slider {
      // bg: #778;
    }
    .vjs-play-progress {
      // background: #aab;
      background: #203277;
      &:before {
        display: none;
        top: -2px;
        z-index: 101;
        @include max($lg) {
          display: block;
        }
      }
    }
    &:hover {
      .vjs-progress-holder {
        height: 12px;
      }
      .vjs-load-progress {
        height: 12px;
      }
    }
  }

  .vjs-button {
    opacity: 0.8;
    transition: all 0.2s;
    > .vjs-icon-placeholder {
      &:before {
        line-height: 34px;
      }
    }
    &:hover {
      opacity: 1;
    }
  }
  .vjs-marker {
    top: 50%;
    margin-top: -6px;
    &.is-own {
      background: #eee !important;
    }
  }

  .vjs-play-control {
    order: 2;
    margin-left: 0;
    > .vjs-icon-placeholder:before {
      font-size: 30px;
    }
  }
  .vjs-seek-button {
    font-size: 20px;
    @include max($sm) {
      display: none;
    }
    &.skip-back {
      order: 3;
    }
    &.skip-forward {
      order: 5;
    }
    > .vjs-icon-placeholder:before {
      font-size: 20px;
    }
  }
  div.vjs-playback-rate {
    order: 4;
    width: 48px;
    padding: 0;
    z-index: 100;
    opacity: 1;
    @include max($sm) {
      display: none;
    }
    .vjs-playback-rate-value {
      background: #fff;
      color: #222;
      height: 18px;
      line-height: 18px;
      font-size: 14px;
      font-weight: bold;
      top: 8px;
    }
    .vjs-menu .vjs-menu-content {
      max-height: 20em;
    }
  }
  .vjs-time-control {
    font-size: 14px;
    font-weight: bold;
    line-height: 34px;
    width: auto;
  }
  .vjs-current-time {
    order: 6;
    display: block;
    &:after {
      content: "/";
      color: #fff;
      position: absolute;
      right: 0;
      margin-right: -4px;
      font-weight: bold;
    }
  }
  .vjs-duration {
    order: 7;
    display: block;
  }
  .vjs-addnote {
    order: 8;
    margin-right: auto;
    position: relative;
    cursor: pointer;
    &:before {
      content: "";
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 17.44" fill="%23ffffff"><polygon points="10.19 15.37 10.19 17.44 12.26 17.44 18.25 11.39 16.19 9.32 10.19 15.37"/><path d="M19.86,9.07l0,0L18.58,7.79a.53.53,0,0,0-.74,0l0,0-1,1,2.07,2.08.93-1A.52.52,0,0,0,19.86,9.07Z"/><rect width="14.45" height="2.89"/><rect y="5.78" width="14.45" height="2.89"/><rect y="11.52" width="8.24" height="2.89"/></svg>')
        50% 50% no-repeat;
      background-size: contain;
      width: 20px;
      height: 18px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .vjs-volume-panel {
    order: 10;
    margin-left: auto;
    width: auto;
    .vjs-mute-control {
      font-size: 14px;
    }
  }

  .vjs-captions-button {
    display: block !important;
    order: 11;
    font-size: 14px;
  }
  .vjs-descriptions-button {
    display: block !important;
    order: 12;
  }

  .vjs-custommenu {
    order: 10;
    position: relative;
    cursor: pointer;
    &:before {
      content: "";
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97.29 92.3" fill="%23ffffff"><circle cx="10.06" cy="46.15" r="10.06" transform="translate(-36.43 37.78) rotate(-67.5)"/><circle cx="10.06" cy="82.24" r="10.06" transform="translate(-55.2 31.2) rotate(-45)"/><circle cx="10.06" cy="10.06" r="10.06" transform="translate(-4.17 10.06) rotate(-45)"/><rect x="29.66" y="1.77" width="67.63" height="16.58"/><rect x="29.66" y="37.86" width="67.63" height="16.58"/><rect x="29.66" y="73.95" width="67.63" height="16.58"/></svg>')
        50% 50% no-repeat;
      background-size: contain;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .vjs-fullscreen-control {
    order: 15;
    @include max($lg) {
      margin-right: 0;
    }
    > .vjs-icon-placeholder:before {
      font-size: 24px;
    }
  }
  .vjs-expandedview {
    order: 16;
    margin-right: 0;
    position: relative;
    cursor: pointer;
    &:before {
      content: "";
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.94 17.08"><path fill="%23ffffff" d="M25.46,0l8.48,8.49L25.46,17V11.31H19.8V5.66h5.66ZM8.49.11V5.77h5.65v5.66H8.49v5.65L0,8.6Z"/></svg>')
        50% 50% no-repeat;
      background-size: contain;
      width: 20px;
      height: 18px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .vjs-big-play-button {
    background: #222;
    border-radius: 50%;
    border: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.16);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 96px;
    height: 96px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0s;
    @include max($sm) {
      width: 48px;
      height: 48px;
    }
    .vjs-icon-placeholder {
      &:before {
        font-size: 85px;
        line-height: 94px;
        @include max($sm) {
          font-size: 42px;
          line-height: 46px;
        }
      }
    }
  }
  &:hover {
    .vjs-big-play-button {
      background: #333;
    }
    .vjs-control-bar {
      opacity: 1;
      visibility: visible;
    }
    .vjs-control {
      .vjs-progress-control {
      }
    }
    .vjs-control-bar {
      // display: block;
    }
  }
  &.vjs-has-started {
    .vjs-big-play-button {
      display: none;
      // animation-name: hideButton;
      // animation-iteration-count: 1;
      // animation-duration: 1s;
      // opacity: 0;
      // visibility: hidden;
    }
    &.vjs-paused {
      .vjs-big-play-button {
        // animation-name: hideButton;
        // animation-iteration-count: 5;
        // animation-duration: 2s;
        // .vjs-icon-placeholder {
        //   &:before {
        //     content: "\f103";
        //   }
        // }
      }
    }
    &.vjs-playing {
      .vjs-big-play-button {
        // display: block;
        // animation-name: hideButton;
        // animation-iteration-count: 5;
        // animation-duration: 2s;
        // .vjs-icon-placeholder {
        //   &:before {
        //     content: "\f101";
        //   }
        // }
      }
    }
  }
  &.vjs-fullscreen {
    .vjs-addnote {
      display: none;
    }
    .vjs-custommenu {
      display: none;
    }
    .vjs-expandedview {
      display: none;
    }
  }
}

.video-animated {
  width: 96px;
  height: 96px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #fff;
  visibility: hidden;
  #animDivPlay,
  #animDivPause {
    width: 96px;
    height: 96px;
    display: block;
    background: rgba(#222, 0.8);
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.16);
    transition: all 0s;
    position: absolute;
    padding: 0;
    // opacity: 1;
    font-family: VideoJS;
    font-size: 85px;
    line-height: 94px;
    animation: hideButton linear 0.2s;
    opacity: 0;
    text-align: center;
  }
  #animDivPlay {
    &:before {
      content: "\f101";
    }
  }
  #animDivPause {
    &:before {
      content: "\f103";
    }
  }
}

@keyframes hideButton {
  0% {
    opacity: 0.5;
    visibility: visible;
    transform: scale(0.9);
  }
  99% {
    visibility: visible;
    transform: scale(1);
    opacity: 0.8;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.video-next {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 1s;
  background: rgba(#000, 0.8);
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  .video-next-i {
  }
  .video-next-label {
    margin-bottom: 10px;
  }
  .video-next-title {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .video-next-play {
    margin-bottom: 10px;
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
      opacity: 0.8;
    }
    .icon {
      width: 64px;
      height: 64px;
    }
  }
  .video-next-cancel {
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
      opacity: 0.8;
    }
  }
}

.video-nav {
  position: absolute;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  transition: all 1s;
  .popper-video-nav-caption {
    border: 0 !important;
    margin: 0 !important;
  }
  .btn-icon {
    padding: 0 3px;
    justify-content: center;
  }
  &.video-nav-prev {
    left: 0;
  }
  &.video-nav-next {
    right: 0;
  }
  .icon {
    width: 10px;
    height: 16px;
  }
  .video-nav-caption {
    // opacity: 0;
    // visibility: hidden;
    // pointer-events: none;
    // // margin-right: 4px;
    // transform: scale(90%);
    // transition: all 0.2s;
    // overflow: hidden;
    white-space: nowrap;
  }
  .btn-icon + .video-nav-caption {
    // margin: 0 0 0 4px;
  }
  // &:hover {
  //   .video-nav-caption {
  //     opacity: 1;
  //     visibility: visible;
  //     transform: none;
  //   }
  // }
}

.course-video {
  &.is-playing {
    .video-nav {
      // display: none;
    }
  }
}

.video-js.vjs-layout-small .vjs-playback-rate {
  display: block;
}
.video-js.vjs-layout-x-small .vjs-playback-rate {
  display: block;
}

.main.no-sidebar {
  .video-js {
    &.vjs-fluid {
    }
    .vjs-control-bar {
      // width: 100vw !important;
      // left: 50%;
      // margin-left: -50vw;
    }
    .vjs-expandedview {
      &:before {
        background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.94 17.08"><path fill="%23ffffff" d="M28.28,17,19.8,8.51,28.28,0V5.69h5.66v5.65H28.28Zm-22.63.08V11.42H0V5.76H5.65V.11l8.49,8.48Z"/></svg>')
          50% 50% no-repeat;
        background-size: contain;
      }
      @include max($lg) {
        display: none;
      }
    }
  }
}

.course-video {
  &.user-active {
  }
  &.user-inactive {
    .video-nav {
      opacity: 0;
      visibility: hidden;
    }
    .vjs-control-bar {
      opacity: 0;
      visibility: hidden;
    }
  }
}

/* Modals */

.video-dialogs {
  .vfm {
    &:nth-child(1) {
      top: 0;
    }
    &:nth-child(2) {
      top: 50px;
    }
    &:nth-child(3) {
      top: 100px;
    }
    &:nth-child(4) {
      top: 150px;
    }
    &:nth-child(5) {
      top: 200px;
    }
    &:nth-child(6) {
      top: 250px;
    }
    &:nth-child(7) {
      top: 300px;
    }
    &:nth-child(8) {
      top: 350px;
    }
  }
}
.modal-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 10px;
  top: 55px !important;
}
.modal-content {
  position: relative;
  overflow: auto;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #d1d7dc;
  box-shadow: 0 0 0 5px rgba(#d1d7dc, 0.5);
  width: 100%;
  max-width: 600px;
  cursor: move;
  &.modal-content-small {
    max-width: 400px;
  }
  .modal-header {
    border-bottom: 1px solid #ccc;
    padding: 5px 10px 5px 20px;
    display: flex;
    align-items: center;
    background: #eee;
    .modal-title {
      margin-right: 30px;
    }
    .modal-close {
      margin-left: auto;
      cursor: pointer;
      width: 32px;
      height: 32px;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .modal-body {
    padding: 10px 20px;
  }
}
