/***** Sidebar *****/

.course-chapters-h {
  display: flex;
  align-items: center;
  padding: 8px 8px 8px 16px;
  h2.course-chapters-title {
    font-size: 16px;
    font-weight: bold;
    margin: 0 20px 0 0;
    letter-spacing: -0.2px;
  }
  .course-sidebar-close {
    margin-left: auto;
    width: 36px;
    height: 36px;
    padding: 8px;
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
      opacity: 0.8;
    }
  }
}
.course-chapters {
  .cs {
    border-top: 1px solid #d1d7dc;
    .cs-h {
      cursor: pointer;
      background: #f7f9fa;
      display: flex;
      padding: 16px;
      align-items: flex-start;
      .cs-h-l {
        margin-right: 20px;
      }
      h3.cs-title {
        font-weight: bold;
        font-size: 16px;
        margin: 0;
      }
      .cs-details {
        margin-top: 4px;
        font-size: 12px;
        .cs-progress {
        }
        .cs-duration {
        }
      }
      .cs-h-icon {
        width: 20px;
        height: 20px;
        margin-left: auto;
        transition: all 0.2s;
        .icon {
        }
      }
    }
    .cs-details .cs-lessons {
    }
    &.is-expanded {
      .cs-h {
        .cs-h-icon {
          transform: rotate(-180deg);
        }
      }
    }
  }
  .cs-lesson {
    padding: 8px 16px;
    display: flex;
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
      background: #d1d7dc;
    }
    &.is-current {
      background: #d1d7dc;
    }
    .cs-lesson-checkbox {
      margin-right: 16px;
      flex: 0 0 16px;
      width: 16px;
    }
    .cs-lesson-info {
      flex: 1;
    }
    .cs-lesson-title {
    }
    .cs-lesson-details {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 8px;
      .cs-lesson-duration-w {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .icon-w {
          width: 16px;
          height: 16px;
          margin-right: 4px;
          color: #666;
        }
        .cs-lesson-duration {
          font-size: 12px;
        }
      }
      .cs-lesson-resources {
        margin-left: auto;
      }
      .popper-dropdown {
        .btn.btn-primary-outline {
          &:hover {
            background: transparent;
          }
        }
        .dropdown-options {
          min-width: 260px;
        }
        .dropdown-option {
          justify-content: flex-start;
        }
      }
    }
  }
}

.sidebar-toggle {
  position: absolute;
  top: 16px;
  left: 100%;
  margin-left: -48px;
  transition: all 0.4s, opacity 1s;
  .btn {
    padding: 0;
    .icon-w {
      // width: 47px;
      // flex: 0 0 47px;
      width: 32px;
      .icon {
        margin-left: auto;
      }
    }
    .btn-txt {
      white-space: nowrap;
      padding-right: 16px;
      font-size: 16px;
      opacity: 0;
      visibility: hidden;
      transition: all 0.4s;
      margin-left: 16px !important;
    }
  }
  &:hover {
    margin-left: 0;
    transform: translateX(-100%);
    opacity: 1;
    visibility: visible;
    .btn {
      .btn-txt {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &.user-active {
  }
  &.user-inactive {
    opacity: 0;
    visibility: hidden;
  }
}

[dir="rtl"] {
  .course-chapters-h {
    padding: 8px 16px 8px 8px;
    h2.course-chapters-title {
      margin: 0 0 0 20px;
    }
    .course-sidebar-close {
      margin: 0 auto 0 0;
    }
  }

  .course-chapters {
    .cs {
      .cs-h {
        .cs-h-l {
          margin: 0 0 0 20px;
        }
        .cs-h-icon {
          margin: 0 auto 0 0;
        }
      }
    }
    .cs-lesson {
      .cs-lesson-checkbox {
        margin: 0 0 0 16px;
      }
      .cs-lesson-details {
        .cs-lesson-duration-w {
          margin: 0 0 0 20px;
          .icon-w {
            margin: 0 0 0 4px;
          }
        }
        .cs-lesson-resources {
          margin: 0 auto 0 0;
        }
        .popper-dropdown {
        }
      }
    }
  }

  .sidebar-toggle {
    margin: 0 -48px 0 0;
    left: auto;
    right: 100%;
    .btn {
      .icon-w {
        .icon {
          margin: 0 auto 0 0;
          transform: scaleX(-1);
        }
      }
      .btn-txt {
        padding: 0 0 0 16px;
        margin-left: 0 !important;
        margin-right: 16px !important;
      }
    }
    &:hover {
      margin-right: 0;
      transform: translateX(100%);
    }
  }
}
