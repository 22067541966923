/***** Footer *****/

.footer {
  color: #fff;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  margin-top: auto;
  .logo {
    display: flex;
    align-items: center;
    line-height: 1;
    img {
      height: 15px;
      flex: 0 0 30px;
      margin-right: 10px;
    }
    span {
      font-size: 18px;
      font-weight: bold;
    }
  }
  .copy {
    margin-left: auto;
  }
}

[dir="rtl"] {
  .footer {
    .logo {
      img {
        margin: 0 0 0 10px;
      }
    }
    .copy {
      margin: 0 auto 0 0;
    }
  }
}
