/***** Buttons *****/

.btn {
  cursor: pointer;
  transition: all 0.2s;
  // display: inline-block;
  text-align: center;
  border-radius: 0;
  padding: 0 12px;
  height: 34px;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
  }
  &.btn-primary {
    background: #222;
    border: none;
    color: #fff;
    &:hover {
      opacity: 0.9;
    }
  }
  &.btn-secondary {
    &:hover {
    }
  }
  &.btn-primary-outline {
    border: 1px solid #222;
    background: none;
    &:hover {
      background: #f5f5f5;
    }
  }
  &.btn-link {
    border: none;
    background: none;
    padding: 0;
  }
  &.btn-secondary-outline {
    background: #222;
    border: 1px solid #6a6f73;
    color: #fff;
    &:hover {
      background: #333;
    }
  }
  &.btn-icon {
    display: flex;
    align-items: center;
    .btn-txt {
      display: flex;
      align-items: center;
      .icon-w + .txt {
        margin-left: 4px;
      }
      .txt + .icon-w {
        margin-left: 4px;
      }
    }
    .icon-w {
      width: 20px;
      height: 20px;
    }
    .btn-txt + .icon-w {
      margin-left: 4px;
    }
    .icon-w + .btn-txt {
      margin-left: 4px;
    }
  }
  &.btn-lg {
    height: 48px;
    font-size: 16px;
  }
  &.btn-sm {
    padding: 0 8px;
    height: 28px;
    font-size: 14px;
  }
  &.btn-block {
    width: 100%;
  }
}
.btns {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 -4px;
  .btn {
    margin: 0 8px 4px 0;
    &:last-child {
      margin-right: 0;
    }
  }
  &.btns-centered {
    justify-content: center;
  }
}

[dir="rtl"] {
  .btn {
    &.btn-icon {
      .btn-txt {
        .icon-w + .txt {
          margin: 0 4px 0 0;
        }
        .txt + .icon-w {
          margin: 0 4px 0 0;
        }
      }
      .btn-txt + .icon-w {
        margin: 0 4px 0 0;
      }
      .icon-w + .btn-txt {
        margin: 0 4px 0 0;
      }
    }
  }
}
