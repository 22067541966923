/***** Components, common blocks *****/

.popper-dropdown {
  margin: 0 !important;
  border: none !important;
  .dropdown-options {
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.08);
    padding: 8px 0;
    border: 1px solid #d1d7dc;
    margin-top: -4px;
  }
  .dropdown-option {
    padding: 8px 16px;
    font-size: 14px;
    text-decoration: none;
    color: #222;
    cursor: default;
    &:hover {
      color: #5624d0;
    }
    &.btn {
      font-weight: normal;
      .icon-w + .btn-txt {
        margin-left: 16px;
      }
    }
  }
}

.ck.ck-toolbar {
  border-color: #222 !important;
}
.ck.ck-editor__editable_inline {
  border-color: #222 !important;
}
.ck.ck-content {
  min-height: 70px;
}
.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
  box-shadow: none !important;
}
