/***** Default and helpers *****/

@import "mixins";
@import "variables";

@font-face {
  font-family: "SF Pro Display";
  src: local("SF Pro Display Bold"), local("SFProDisplay-Bold"),
    url("../fonts/SFProDisplay-Bold.woff2") format("woff2"),
    url("../fonts/SFProDisplay-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: local("SF Pro Display Regular"), local("SFProDisplay-Regular"),
    url("../fonts/SFProDisplay-Regular.woff2") format("woff2"),
    url("../fonts/SFProDisplay-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html {
  padding: 0;
  margin: 0;
  height: 100%;
}
body {
  overflow-x: hidden;
  color: $black;
  font-family: $font-basic;
  font-size: 16px;
  height: 100%;
  line-height: 1.4;
  background: #222;
}

* {
  box-sizing: border-box;
  &:before,
  &:after {
    box-sizing: border-box;
  }
}

input[type="submit"],
input[type="text"],
input[type="number"],
input[type="tel"],
input[type="email"],
input[type="password"],
input[type="url"],
textarea {
  -webkit-appearance: none;
  appearance: none;
}

input,
textarea,
label {
  font-family: $font-basic;
}
a,
button,
input,
textarea,
select {
  outline: none;
}

input,
textarea {
  @include placeholder {
    opacity: 0.6;
  }
  &:focus {
    @include placeholder {
      opacity: 0.4;
    }
  }
}

textarea {
  resize: vertical;
}

table {
  border-collapse: collapse;
}

a {
  color: #52d;
  text-decoration: none;
  transition: all 0.2s;
  &:hover {
    color: #419;
  }
}

h1,
h2,
h3,
h4 {
  font-family: $font-heading;
  line-height: 1.2;
}

h1 {
}
h2 {
}
h3 {
}
h4 {
}

p {
  margin: 0;
  + p {
    margin-top: 8px;
  }
}

b,
strong {
}

img {
  max-width: 100%;
  height: auto;
}

ul {
  margin: 8px 0 8px 24px;
  padding-left: 24px;
  @include max($sm) {
    padding: 0;
  }
  li {
    margin: 0;
    padding: 0 0 0 8px;
  }
}

pre code {
  padding: 2px 4px;
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 14px;
  color: #946022;
  background: #fff;
  border: 1px solid #ddd;
}
