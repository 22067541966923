/***** Layout *****/

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;
  max-width: $xl;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100vh;
  &[v-cloak] {
    display: none;
  }
  .app-i {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}
.main {
  flex: 1;
  display: flex;
  align-items: stretch;
  position: relative;
}
.section-video {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.content {
  background: #fff;
  flex: 1;
}
.sidebar {
  width: 25%;
  flex: 0 0 25%;
  background: #fff;
  border: 1px solid #f7f9fa;
  @include max($lg) {
    width: 100%;
    display: block;
  }
}
