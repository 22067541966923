/**** Form elements *****/

.form-group {
  margin-bottom: 20px;
}
.form-control {
  border: 1px solid #222;
  background: #fff;
  border-radius: 0;
  display: block;
  width: 100%;
  padding: 12px 16px;
  line-height: 22px;
  font-size: 16px;
  height: 48px;
}

// .select-container {
//   position: relative;
//   border: 1px solid #ccc;
//   border-radius: 5px;
//   background: #fff;
//   max-width: 100%;
//   overflow: hidden;
//   height: 40px;
//   display: flex;
//   align-items: stretch;
//   transition: all 0.3s;
//   &.focused {
//     border-color: #aaa;
//   }
//   @include max($xxxs) {
//     width: 100%;

//   }
//   &:after {
//     content: "";
//     display: block;
//     position: absolute;
//     right: 10px;
//     top: 13px;
//     width: 17px;
//     height: 10px;
//     background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 173.8 98.6"><path class="a" d="M96.8,98.6,183.8,0H10Z" transform="translate(-10 0)"/></svg>') 50% 50% no-repeat;
//     background-size: contain;
//   }
//   select {
//     appearance: none;
//     border: none;
//     background: none;
//     font-size: 16px;
//     font-weight: normal;
//     padding: 8px 40px 8px 12px;
//     height: 38px;
//     line-height: 22px;
//     width: 100%;
//     @include max($xxxs) {
//       height: 30px;
//     }
//   }
// }

// .form-check {
//   margin-bottom: 10px;
// }
.custom-checkbox {
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    z-index: -1;
    + span {
      position: relative;
      padding: 0 0 0 30px;
      display: block;
      &:before {
        content: "";
        border: 2px solid #000;
        width: 16px;
        height: 16px;
        position: absolute;
        left: 0;
        top: 1px;
        box-sizing: border-box;
      }
    }
    &:checked {
      + span {
        &:before {
          background: #000;
        }
        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 3px;
          left: 2px;
          width: 12px;
          height: 12px;
          background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="%23ffffff" d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"/></svg>');
          background-size: contain;
        }
      }
    }
    &:focus {
      + span {
        &:before {
        }
      }
    }
    &:not(:disabled) {
      span {
        &:hover {
          &:before {
          }
        }
      }
    }
  }
}

// .custom-radio {
//   input[type="radio"] {
//     position: absolute;
//     opacity: 0;
//     z-index: -1;
//     + span {
//       position: relative;
//       padding: 0 0 0 28px;
//       display: block;
//       &:before {
//         content: '';
//         display: block;
//         border: 1px solid #000;
//         border-radius: 50%;
//         background: #E5E5E5;
//         box-shadow: 1px 1px 0 #A6A6A6 inset;
//         width: 16px;
//         height: 16px;
//         position: absolute;
//         left: 0;
//         top: 2px;
//         box-sizing: border-box;
//       }
//       &:after {
//         content: "";
//         display: none;
//         position: absolute;
//         left: 4px;
//         top: 6px;
//         width: 8px;
//         height: 8px;
//         border-radius: 50%;
//         z-index: 1;
//       }
//     }
//     &:checked {
//       + span {
//         &:before {

//         }
//         &:after {
//           display: block;
//         }
//       }
//     }
//     &:focus {
//       + span {
//         &:before {
//           background: #f5f5f5;
//         }
//       }
//     }
//     &:not(:disabled) {
//       span {
//         &:hover {
//           &:before {
//             background: #f5f5f5;
//           }
//         }
//       }
//     }
//   }

//   input[type="radio"] {
//     & + span.radio-info {
//       font-weight: normal;
//       &:before {
//         top: 4px;
//       }
//       &:after {
//         top: 8px;
//       }
//       .radio-info-h {
//         display: flex;
//         align-items: center;
//         margin-bottom: 5px;
//         color: #000;
//         font-size: 19px;
//         font-weight: normal;
//         line-height: 24px;
//         span {}
//         i.icon {
//           margin-left: 7px;
//         }
//       }
//       .radio-info-desc {
//         display: block;
//         font-size: 16px;
//         color: $dgrey;
//       }
//     }
//   }
// }

[dir="rtl"] {
  .custom-checkbox {
    input[type="checkbox"] {
      + span {
        padding: 0 30px 0 0;
        &:before {
          right: 0;
          left: auto;
        }
      }
      &:checked {
        + span {
          &:after {
            right: 2px;
            left: auto;
          }
        }
      }
    }
  }
}
